var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { BaseApi } from './base-api';
export class CrudApi extends BaseApi {
  //protected mockService: AbstractDtoMock<DTO>;
  constructor(serviceName) {
    super(serviceName);
  }
  /**
   * find by uid
   * @param uid of the object to find
   * @param lang client language
   * @returns object found
   */
  find(uid, lang) {
    return __awaiter(this, void 0, void 0, function* () {
      /*if (MockService.getInstance().isMockEnabledByService(this.serviceName)) {
        const list: PagingResponse<DTO> = await this.mockService.fakeList('/list', 0, 100, 'fr');
        const obj: DTO | undefined = list.results.find((r: DTO) => r.uid == uid);
        return obj || list.results[Math.floor(Math.random() * list.results.length)];
      } else {*/
      return this.get(uid, {
        headers: this.getCrudHeaders(lang)
      });
      /*}*/
    });
  }
  /**
   *  List LIST_RES by criteria.
   *  You can use criteria defined in the LIST_REQ as a body payload.
   *  In addition, you can use the limit and page query parameters to paginate the results.
   *  Default limit is 50 and default page is 1.
   *
   * User must be authenticated to list objects.
   * @param filters to apply
   * @param page number of the page
   * @param limit limit of the page
   * @param lang client language
   * @returns paginated list of objects
   */
  list(filters, page = 1, limit = 50, lang) {
    return __awaiter(this, void 0, void 0, function* () {
      /* if (MockService.getInstance().isMockEnabledByService(this.serviceName)) {
         return this.mockService.fakeList('/list', page, pageSize, lang);
       } else {*/
      const queryParams = this.queryParamService.buildListQueryParams(page, limit);
      const res = yield this.post(`list` + queryParams, filters, {
        headers: this.getCrudHeaders(lang)
      });
      return res;
      //}
    });
  }
  /**
   * Create multiple items.
   * you can use the CREATE_REQ as a body payload.
   * If you want to create a single item , you can use the create endpoint to avoid sending and receiving an array.
   * This endpoint will return the created items in an array.
   *
   * You must be a content editor to create items.
   * @param dtos objects to create
   * @param lang client language
   * @returns inserted or updated objects
   */
  createMany(dtos, lang) {
    return __awaiter(this, void 0, void 0, function* () {
      /* if (MockService.getInstance().isMockEnabledByService(this.serviceName)) {
         return [];
       } else {*/
      return this.post('many', dtos, {
        headers: this.getCrudHeaders(lang)
      });
      /* }*/
    });
  }
  /**
   * Create a CREATE_RES.
   * You can use the item as a body payload.
   * If you want to create multiple items , use the /many endpoint.
   * This endpoint will return the created cultivation.
   *
   * You must be a content editor to create an item.
   * @param item object to create
   * @param lang client language
   * @returns inserted or updated object
   */
  create(item, lang) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.post('', item, {
        headers: this.getCrudHeaders(lang)
      });
    });
  }
  /**
   * Update multiple items.
   * you can use the UPDATE_REQ as a body payload.
   * If you want to create a single item , you can use the create endpoint to avoid sending and receiving an array.
   * This endpoint will return the created items in an array.
   *
   * You must be a content editor to create items.
   * @param dtos objects to update
   * @param lang client language
   * @returns updated objects
   */
  updateMany(dtos, lang) {
    return __awaiter(this, void 0, void 0, function* () {
      /* if (MockService.getInstance().isMockEnabledByService(this.serviceName)) {
         return [];
       } else {*/
      return this.patch('many', dtos, {
        headers: this.getCrudHeaders(lang)
      });
      /* }*/
    });
  }
  /**
   * Update a UPDATE_RES.
   * You can use the item as a body payload.
   * If you want to create multiple items , use the /many endpoint.
   * This endpoint will return the created cultivation.
   *
   * You must be a content editor to create an item.
   * @param item object to update
   * @param lang client language
   * @returns updated object
   */
  update(item, lang) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.patch('', item, {
        headers: this.getCrudHeaders(lang)
      });
    });
  }
  /**
   * delete by id
   * @param uid of the object to delete
   * @param lang client language
   * @returns void
   */
  deleteById(uid, lang) {
    return __awaiter(this, void 0, void 0, function* () {
      /*if (MockService.getInstance().isMockEnabledByService(this.serviceName)) {
        return;
      } else {*/
      return this.delete(uid, {
        headers: this.getCrudHeaders(lang)
      });
      //}
    });
  }
  /**
   * @param addressUid the address uid
   * @returns return headers with token and language
   */
  getCrudHeaders(lang, addressUid) {
    const headers = {
      'Content-Type': 'application/json'
    };
    if (lang) {
      headers['content-language'] = lang;
    }
    // headers['authorization'] = `Bearer ${this.getToken()}`;
    return headers;
  }
}