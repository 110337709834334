var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { CrudApi } from "../../@common/crud-api";
export class AddressToUserApi extends CrudApi {
  constructor() {
    super('address-to-user');
  }
  findForUser(addressUid, userUid) {
    return __awaiter(this, void 0, void 0, function* () {
      const headers = this.getHeaders(addressUid);
      return this.get(`${addressUid}/${userUid}`, {
        headers
      });
    });
  }
}